import React, { useState, useEffect } from "react";

const DataContext:any = React.createContext('');

function DataProvider(props:any) {
  // const [sample_data, SetSampleData] = useState("");
  const [app_data, SetAppData] = useState({
    brand_id: "",
    outlet_id: "",
    logged_in: false,
    user_data: {},
    settings: {},
    user_details: {},
    application: {},
    have_footer: true,
    ibtn_link: "",
    show_chat_btn: false,
    image_base_url: "",
    previous_request: {},
    new_application_status: 0,
    force_superapp_redirect: false,
    home_page: false,
    tutorials: {},
    bg_location_service: false,
    bg_location_access: '',
  });

  useEffect(() => {
    if (props.application) {
      SetAppData((prevValue) => {
        prevValue.application = props.application;
        return { ...prevValue };
      });
    }
    if (props.user_details) {
      SetAppData((prevValue) => {
        prevValue.user_details = props.user_details;
        return { ...prevValue };
      });
    }
    if (props.settings) {
      SetAppData((prevValue) => {
        prevValue.settings = props.settings;
        return { ...prevValue };
      });
    }

    if (props.logged_in) {
      SetAppData((prevValue) => {
        prevValue.logged_in = props.logged_in;
        return { ...prevValue };
      });
    }
    if(props.have_footer){
      SetAppData((prevValue) => {
        prevValue.have_footer = props.have_footer;
        return { ...prevValue };
      });
    }
    if(props.ibtn_link){
      SetAppData((prevValue) => {
        prevValue.ibtn_link = props.ibtn_link;
        return { ...prevValue };
      });
    }
    if(props.show_chat_btn){
      SetAppData((prevValue) => {
        prevValue.show_chat_btn = props.show_chat_btn;
        return { ...prevValue };
      });
    }
    if(props.image_base_url){
      SetAppData((prevValue) => {
        prevValue.image_base_url = props.image_base_url;
        return { ...prevValue };
      });
    }
    if(props.previous_request){
      SetAppData((prevValue) => {
        prevValue.previous_request = props.previous_request;
        return { ...prevValue };
      });
    }
    if(props.new_application_status){
      SetAppData((prevValue) => {
        prevValue.new_application_status = props.new_application_status;
        return { ...prevValue };
      });
    }
    if(props.force_superapp_redirect){
      SetAppData((prevValue) => {
        prevValue.force_superapp_redirect = props.force_superapp_redirect;
        return { ...prevValue };
      });
    }
    if(props.tutorials){
      SetAppData((prevValue) => {
        prevValue.tutorials = props.tutorials;
        return { ...prevValue };
      });
    }
    if(props.home_page){
      SetAppData((prevValue) => {
        prevValue.home_page = props.home_page;
        return { ...prevValue };
      });
    }
    if(props.bg_location_service || props.bg_location_access){
      SetAppData((prevValue) => {
        if(prevValue.bg_location_service != props.bg_location_service){
          prevValue.bg_location_service = props.bg_location_service;
        }
        if(prevValue.bg_location_access != props.bg_location_access){
          prevValue.bg_location_access = props.bg_location_access;
        }
        return { ...prevValue };
      });
    }
  }, [props]);

  return (
    <DataContext.Provider
      value={{
        app_data,
        SetAppData,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}
export { DataProvider };

// export const DataProvider = DataContext.Provider;
export const DataConsumer = DataContext.Consumer;

export default DataContext;
