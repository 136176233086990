import { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";

export default function OrderCard(props: any) {
  let pickup: any = {},
    drop: any = {},
    pickup_address: any,
    delivery_address: any;
  delivery_address = props.order_data.delivery_address;

  if (props.order_data.sector_id == 4) {
    pickup_address = props.order_data.pickup_address;
    pickup.name = pickup_address.first_name;
    if (pickup_address.last_name != null) {
      pickup.name += " " + pickup_address.last_name;
    }
    if (pickup_address.address && pickup_address.city) {
      pickup.address = pickup_address.address + ", " + pickup_address.city;
    } else if (pickup_address.map_address) {
      pickup.address = pickup_address.map_address;
    }
  } else {
    pickup.name = props.order_data.brand_name;
    pickup.address = "";
    if (props.order_data.outlet?.name) {
      pickup.address = props.order_data.outlet?.name;
    }
    if (props.order_data.outlet?.address) {
      pickup.address += ", " + props.order_data.outlet?.address;
    }
    if (props.order_data.outlet?.city) {
      pickup.address += ", " + props.order_data.outlet?.city;
    }
  }

  drop.name = delivery_address.first_name;
  if (delivery_address.last_name != null) {
    drop.name += " " + delivery_address.last_name;
  }
  if (delivery_address.address && delivery_address.city) {
    drop.address = delivery_address.address + ", " + delivery_address.city;
  } else if (delivery_address.map_address) {
    drop.address = delivery_address.map_address;
  }
  return (
    <Link
      to={"/order/" + props.order_data.id}
      className="link-text mb-2 d-block"
    >
      <div className="box-1 p-3">
        <div className="d-flex mb-2">
          <div className="text-center">
            <div>
              <i className="fas fa-arrow-circle-up text-success fs-15"></i>
            </div>
            <div className="dotted-v-line"></div>
          </div>
          <div className="ms-3">
            <div className="fw-500">{pickup.name}</div>
            <div className="fs-13">{pickup.address}</div>
          </div>
        </div>
        <div className="d-flex">
          <div className="text-center">
            <div>
              <i className="fas fa-arrow-circle-down text-primary fs-15"></i>
            </div>
          </div>
          <div className="ms-3">
            <div className="fw-500">{drop.name}</div>
            <div className="fs-13">{drop.address}</div>
          </div>
        </div>
        {!props?.hide?.distance_duration && (
          <div className="row text-center mt-3">
            <div className="border-right col-4 fw-500">
              <i className="fas fa-motorcycle fs-13"></i>{" "}
              {props.order_data.distance} kms
            </div>
            <div className="border-right col-4 fw-500">
              <i className="fas fa-clock fs-13"></i>{" "}
              {Math.floor(props.order_data.duration / 60)} mins
            </div>
            <div className="col-4 fw-500">
              &#x20B9;{props.order_data.partner_amount}
            </div>
          </div>
        )}
      </div>
    </Link>
  );
}
