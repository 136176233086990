import { useState, useEffect } from "react";

function Header(props: any) {
  const [data, SetData]: any = useState({});
  useEffect(() => {
    if (props) {
      SetData(props.data);
    }
  }, [props]);
  return (
    <div className="d-flex mb-3">
      <div>
        {parseInt(data.status_id) < 7 ? (
          <div className="d-flex">
            <div className="me-3 fw-500">
              <i className="fas fa-biking fs-13 text-muted"></i>{" "}
              {data.distance} kms
            </div>
            <div className="fw-500">
              <i className="far fa-clock fs-13 text-muted me-1"></i>
              {Math.floor(data.duration / 60)} mins
            </div>
          </div>
        ) : (
          <div className="fs-13">{data.time}</div>
        )}
        <div className="d-flex align-items-center mt-1">
          <div className="fs-13 me-2">#{data.id}</div>
          <div className={data.payment_type == 2?"badge alert-badge fs-12 fw-500":"badge success-badge fs-12 fw-500"}>{data.payment_type_label}</div>
        </div>
      </div>
      <div className="ms-auto fw-500 text-end">
        <div>&#x20B9; {data.amount}</div>
        <div className="fs-13 text-primary mt-1">{data.status}</div>
      </div>
    </div>
  );
}
export default Header;
