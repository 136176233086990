export const AppConfig:any = {
  base_url: {
    api: 'https://'+ window.location.hostname +'/app', // for live
    // api: "https://deliverypartner.1superapp.com/app",
    // api: "https://1sdev.deliverypartner.1superapp.com/app",
    superapp: "https://",
  },
  user_agent: {
    superapp: {
      android: "",
      ios: "",
    },
  },
  CDN_Media_url : "https://cdn3.1superapp.com/media/",
  CDN_Image_url : "https://cdn2.1superapp.com/images/",
  environment : "Production",
  // environment : "Development",
  // token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbiI6IjM0MGI5NDhlYTNlY2NhMTg1YTFmZjQ2OTg5NzhlNmJmIiwidXNlcm5hbWUiOiJuaXJtYWxkdXJhaSIsIm5hbWUiOiJOaXJtYWwgRHVyYWkgWSJ9.W7tTiFjohEbdBOdiPWAjRGqfSbXvbX7m-v-ycE3o1sHAmcw3FfhAJ5q0ivx_mh4H0E50Cm-7XolnYZ9qzMejrttlqFvvBzseQ3Oz-JnmD9me_GFaQpqVm2QXTjjOx2r5bOEuNINuPXo-hBNhwBIZsyGE1o3WzfoFgIV-tCOsYaA-jU4NCC5hq2jsmhJghmcLtppduCTw4nqKgwIzncufGUms1lQBEuyCV1wSzB8vN-SZIQxqdT7JFE1AglMixgkS_3CsYrMUJ6H-jd8KLjP07LnZWtk3JRacrSV7b-XXNUaotCyBEKsv7vkDf50fWZYfQZkH5PO0iAeqwvaGNmCy0A",
  // token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbiI6ImY0NTZjZWQyZDljODdkODMyZmQ1MjZkMmU4NzY0YjhlIiwidXNlcm5hbWUiOiJkZW1vNiIsIm5hbWUiOiJEZW1vIDYifQ.kx3tGVdT004M8adza-CFAf44rNVbtWc41t2Hm1tK7wkGPwt68vgDciOm2nFEHooeu-sL9Q5AnXnPl30wFuDQvYk2I5xda3Ck-WOKRiPt4R2Kic0mo6hDjjpAA1KTeR9IPd3OyzymH0fjcQxMBrBOaKlNFDJ35BD8UAwfHO5zoB29aogx4Vi6P4M-1-039J8Rsqs8wpGXi9tjIl3qVTNePYVK8bGo1DDRdKnObcKFjb0xqZsrlcsmjNK9dPmBuaA2-dFkrez-X-z-Nd3-8jMC7Hn6-xUTHFW2d4v1f2sWjXmqswRx5inezVs1891BMNQlCqO5oIC-4OLLFZJq6V8XBA",
  // token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbiI6ImU2ZTAwMzg5YjA3MDdlNWIyMjkyMWZiNDBkZDQ2MWQ3IiwidXNlcm5hbWUiOiJkZW1vMyIsIm5hbWUiOiJEZW1vIDMifQ.EZ2Xu-tj1hw8Y74sWZgXJB-gbt-sltxeHampV2Q7YeCdE8iXn6sDYYR_DpG6IiOsDRjs3FTXAQ4RHfZPAcCQMKk66-CiV3mhlTsFZXh1SPd1YSUqTcdlssqAch65qATcUOwsTr0E6qpwEcSoPABlE-XBFTTEzaUKIYKGE8E5KdWiI__UgCzwxL_-UZRXCdDgzHCfn-CtWCGI7PCmMf769Mn-iWuODtKZXytTgt5dAdNvNFBkn7fyqsNraUDIKsONr2c3k9S7pmQfEqAl54RvBlMzmX-fBjxOVH1MpnJR85YPsLK2eNmE7yxCMCwx5dlYKZfR6_kgyi9eniQjK0ex1w",
  // token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbiI6IjkwYmFjNzM5ODdiOWVmNjlkMThhNTI0NjNjYzY1YzcxIiwidXNlcm5hbWUiOiJWTUhGdEVYYkdQIiwibmFtZSI6Ik5pcm1hbCJ9.dEO8LOydp_Qjem30udSxBfklllmBHoQCLvG3f2PszaFfOXaXSOKMCqRJin-v3_oJ5BF725wd1MfFGsXmHuQFEvhAnwFPatZsN_tt8x7eFzGNy8RjUY42S0icv2sM1Bpqfj5-tJGW8dXaW_teSj0J-R-XMAVgrL3lxt92YPYqe-zWCPIXkcau8OuQt4AJiKg1Dy7R62CCdTNDdO0AT55LpMK2FRuBrLQ_FHPWMIZAIKY08QFTPGBncTsBZjwzzV4sXlKr_TF6uKTjoURZBZyrYA9_PWyLrfHuy7vdej6mSfx84K8R0j7v2d4KV6rGfE8X_Ept6iCDKBYk-xMe193L-Q",
};
