import React, { useState, useEffect, useContext } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import { Dialog, Modal, Rating } from "@mui/material";
import { useRef } from "react";
import { AppHelper } from "../../utils/Helper";

import { api } from "../../utils/Api";
import { mobileApp } from "../../utils/MobileApp";
import DataContext from "../../utils/Context/DataContext";

function Pickup(props: any) {
  const context: any = useContext(DataContext);
  const [save_data, SetSaveData]: any = useState({});
  const [capture_img, SetCaptureImg] = useState("");
  const [outer_data, SetOuterData]: any = useState([]);

  const [pickup, setPickup]: any = useState({});
  const [startBtnLoader, setStartBtnLoader]: any = useState(false);
  const [callBtnLoader, setCallBtnLoader]: any = useState(false);

  useEffect(() => {
    if (props) {
      SetSaveData(props.data);
      SetOuterData(props.outer_data);
    }
  }, [props]);

  useEffect(() => {
    let pickupdata: any = {};
    if (parseInt(save_data.sector_id) == 4) {
      pickupdata.name = save_data?.pickup_address?.first_name;
      if (save_data?.pickup_address?.last_name != null) {
        pickupdata.name += " " + save_data?.pickup_address?.last_name;
      }
      pickupdata.lat = save_data?.pickup_address?.lat;
      pickupdata.lng = save_data?.pickup_address?.lng;
      if (
        save_data?.pickup_address?.city != null &&
        save_data?.pickup_address?.city
      ) {
        pickupdata.address = "";
        if (save_data?.pickup_address?.door_no != null) {
          pickupdata.address != ""
            ? (pickupdata.address += ", " + save_data?.pickup_address?.door_no)
            : (pickupdata.address = save_data?.pickup_address?.door_no);
        }
        if (save_data?.pickup_address?.address != null) {
          pickupdata.address != ""
            ? (pickupdata.address += ", " + save_data?.pickup_address?.address)
            : (pickupdata.address = save_data?.pickup_address?.address);
        }
        if (save_data?.pickup_address?.city != null) {
          pickupdata.address != ""
            ? (pickupdata.address += ", " + save_data?.pickup_address?.city)
            : (pickupdata.address = save_data?.pickup_address?.city);
        }
        if (save_data?.pickup_address?.pincode != null) {
          pickupdata.address != ""
            ? (pickupdata.address += ", " + save_data?.pickup_address?.pincode)
            : (pickupdata.address = save_data?.pickup_address?.pincode);
        }
      } else if (save_data?.pickup_address?.map_address != null) {
        pickupdata.address = save_data?.pickup_address?.map_address;
      }
      if (save_data?.pickup_address?.landmark != null) {
        pickupdata.landmark = save_data?.pickup_address?.landmark;
      }
      if (save_data?.pickup_address?.mobile != null) {
        pickupdata.mobile = save_data?.pickup_address?.mobile;
      }
    } else {
      pickupdata.name = save_data?.brand_name;
      pickupdata.lat = save_data?.outlet?.lat;
      pickupdata.lng = save_data?.outlet?.lng;
      pickupdata.address = "";
      if (save_data?.outlet?.name != null) {
        pickupdata.address = save_data?.outlet?.name;
      }
      if (save_data?.outlet?.city != null && save_data?.outlet?.city) {
        if (save_data?.outlet?.door_no != null) {
          pickupdata.address != ""
            ? (pickupdata.address += ", " + save_data?.outlet?.door_no)
            : (pickupdata.address = save_data?.outlet?.door_no);
        }
        if (save_data?.outlet?.address != null) {
          pickupdata.address != ""
            ? (pickupdata.address += ", " + save_data?.outlet?.address)
            : (pickupdata.address = save_data?.outlet?.address);
        }
        if (save_data?.outlet?.city != null) {
          pickupdata.address != ""
            ? (pickupdata.address += ", " + save_data?.outlet?.city)
            : (pickupdata.address = save_data?.outlet?.city);
        }
        if (save_data?.outlet?.pincode != null) {
          pickupdata.address != ""
            ? (pickupdata.address += ", " + save_data?.outlet?.pincode)
            : (pickupdata.address = save_data?.outlet?.pincode);
        }
      } else if (save_data?.outlet?.map_address != null) {
        pickupdata.address = save_data?.outlet?.map_address;
      }
      if (save_data?.outlet?.mobile != null) {
        pickupdata.mobile = save_data?.outlet?.mobile;
      }
    }
    setPickup(pickupdata);
  }, [save_data]);

  function openMap(dir_lat: any, dir_lng: any) {
    let gmap_url = "https://www.google.com/maps/dir/?api=1&destination=" +
    dir_lat +
    "," +
    dir_lng +
    "&travelmode=driving"
    if (
      context.app_data.application.platform == "android" ||
      context.app_data.application.platform == "ios"
    ) {
      mobileApp({
        function: "openurlapp",
        app_type: "react_web",
        url: gmap_url
      });
    } else {
      window.open(
        gmap_url,
        "_blank"
      );
    }
  }

  async function call_pickup(order_id:any) {
    setStartBtnLoader(true);
    let pass_data = {
      post: {
        order_id: order_id,
        callto: "pickup",
      },
    };
    let data: any = await api("/company/call", pass_data);
    if (data.status_code == 200) {
      if(data?.response?.phone_number){
        if(context.app_data.application.platform == "android"){
          window.open("tel:"+data?.response?.phone_number);
        } else {
          window.location.href = "tel:"+data?.response?.phone_number;
        }
      }
    }
    setStartBtnLoader(false);
  }

  async function chat_pickup(order_id:any) {
    setCallBtnLoader(true);
    let pass_data = {
      post: {
        order_id: order_id,
        chatto: "pickup",
      },
    };
    let data: any = await api("/company/chat", pass_data);
    if (data.status_code == 200) {
      if(data?.response?.sb_user_id && data?.response?.channel_url){
        props?.chatCallback({
          orderId: save_data.id,
          sb_user_id: data?.response?.sb_user_id,
          channel_url: data?.response?.channel_url,
          title: data?.response?.user_name,
          profile_url: data?.response?.profile_url,
        });
      }
    }
    setCallBtnLoader(false);
  }

  return (
    <>
      <div className="box-1 p-3 mb-3 d-flex align-items-center">
        <div className="w-100">
          {!props?.hideHeading && (
            <div className="mb-1 fw-500 text-dark">Pickup</div>
          )}
          <div className="d-flex">
            <div className="text-muted fs-13">
              <div>
                <span className="fw-500">{pickup.name}</span>{(pickup.name != "" && pickup.name != null)?',':''} {pickup.address}
              </div>
              {save_data?.pickup?.distance != 0 && (
                <div className="d-flex fs-13 mt-1">
                  {save_data?.pickup?.distance != 0 && (
                    <div>
                      <i className="fas fa-biking me-1"></i>
                      {save_data?.pickup?.distance} kms
                    </div>
                  )}
                  <div className="px-1 fs-12">|</div>
                  {save_data?.pickup?.duration != 0 && (
                    <div>
                      <i className="far fa-clock me-1"></i>
                      {Math.floor(save_data?.pickup?.duration / 60)} mins
                    </div>
                  )}
                </div>
              )}
            </div>
            {!props?.order_page && parseInt(save_data.status_id) != 0 &&
              parseInt(save_data.status_id) != 2 &&
              parseInt(save_data.status_id) < 7 && (
                <div className="ms-auto ps-2">
                  <button
                    type="button"
                    className="btn text-primary fs-30 py-0 px-1"
                    onClick={() => openMap(pickup?.lat, pickup?.lng)}
                  >
                    <i className="fas fa-directions"></i>
                  </button>
                </div>
              )}
          </div>
          {/* {save_data?.pickup?.pickedup_time != null && (
              <div className="fs-12 flex-fill text-center">
                {save_data?.pickup?.pickedup_time}
                <div className="fw-500">Picked At</div>
              </div>
            )} */}
          {!props?.order_page && (
            <div className="d-flex mt-2">
              {pickup?.mobile == true && (
                <button
                  onClick={() => {
                    call_pickup(save_data.id);
                  }}
                  className="btn btn-light text-primary w-50 text-uppercase me-1"
                >
                  {startBtnLoader ? (
                    <span className="btn-loader"></span>
                  ):(
                    <span><i className="fas fa-phone-alt"></i> Call</span>
                  )}
                </button>
              )}
              {(parseInt(save_data.status_id) != 0 && parseInt(save_data.status_id) != 2 && parseInt(save_data.status_id) >= 1 && parseInt(save_data.status_id) < 10) && (
                <button
                  onClick={() => {
                    chat_pickup(save_data.id);
                  }}
                  className="btn btn-light text-primary w-50 text-uppercase"
                >
                  {callBtnLoader ? (
                    <span className="btn-loader"></span>
                  ):(
                    <span><i className="far fa-comments"></i> Chat</span>
                  )}
                </button>
              )}
            </div>
          )}
        </div>
        {props?.order_page && (
          <div>
            <i className="fas fa-chevron-right ms-auto text-muted"></i>
          </div>
        )}
      </div>
    </>
  );
}

export default Pickup;
