import React, { useState, useEffect, useContext } from "react";
import { api } from "./utils/Api";

import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { DataProvider } from "./utils/Context/DataContext";
import { mobileApp } from "./utils/MobileApp";

//css
import "./assets/libraries/bootstrap/css/bootstrap.min.css";
import "./assets/libraries/fontawesome/css/all.min.css";
import "./assets/css/style.css";

//js
// import "bootstrap/dist/js/bootstrap.min.js";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import "./assets/libraries/bootstrap/js/bootstrap.min.js";
// import "./assets/libraries/bootstrap/js/bootstrap.bundle.min.js";

import HomeLayout from "./component/HomeLayout/HomeLayout";
import Home from "./page/Home/Home";
import Orders from "./page/Orders/Orders";
import OrderStatus from "./page/Order/OrderDetails";
// import OrderMap from "./page/Order/OrderMap";

import Signup from "./page/Signup/Signup";
import ActivationForm from "./page/Signup/ActivationForm";
import SignupSuccess from "./page/Signup/SignupSuccess";
import Notification from "./page/Notification/Notification";
import Profile from "./page/Profile/Profile";
import OrderDetails from "./page/OrderDetails";
import Company from "./page/Company/Company";
import Order from "./page/OrderDetails/Order";
import Companies from "./page/Companies";
import Settlement from "./page/Settlement/Settlement";

// declare global {
//   interface Window { fcWidgetMessengerConfig: any, fcWidget:any }
// }

export default function App() {
  const [loading, setLoading] = useState(true);
  const [hasNewOrder, setHasNewOrder] = useState(false);

  const [settings, SetSettings] = useState({});
  const [previous_request, setPreviousRequest] = useState({});
  const [new_application_status, setNewApplicationStauts] = useState(0);
  const [user_details, SetUserDetails] = useState({});
  const [imageBaseUrl, setImageBaseUrl] = useState("");

  const [application, SetApplication] = useState({ platform: "web" });
  const [tutorials, SetTutorials] = useState({});
  const [bg_location_service, setBgLocationService] = useState(false);
  const [bg_location_access, setBgLocationAccess] = useState('');

  let newOrderTimer:any;

  function putSuperAppFuncionCallback(message: any) {
    if (message.data.function == "checkdeliverypartnerbglocation") {
      if (message.data.status) {
        setLoading(false);
        setBgLocationAccess('');
      }
    } else if (message.data.function == "startdeliverypartnerbglocation") {
      setLoading(false);
      if (message.data.status) {
        setBgLocationAccess('');
      }
    }
  }
  useEffect(() => {
    document.addEventListener("message", putSuperAppFuncionCallback);
    init();

    // window.fcWidgetMessengerConfig = {
    //   config: {
    //     headerProperty:{
    //       hideChatButton: true,
    //     }
    //   }
    // }

    // let fw_chat:any = document.createElement("script");
    // fw_chat.src = '//in.fw-cdn.com/31537612/760166.js';
    // fw_chat.setAttribute('chat', true);
    // document.head.append(fw_chat);

    return () => {
      if(newOrderTimer){
        clearInterval(newOrderTimer);
      }
    }
  }, []);
  useEffect(() => {
    if(hasNewOrder){
      const ring = {
        function: "startringing",
        app_type: "react_web",
      };
      mobileApp(ring);
    } else {
      const ring = {
        function: "refreshringnotifications",
        app_type: "react_web",
      };
      mobileApp(ring);
    }
  }, [hasNewOrder]);
  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(showPosition);
    }
  }
  async function getNewOrder() {
    // if(application.platform == 'android'){
      let data: any = await api("/company/hno");
      if (data.status_code == 200) {
          setHasNewOrder(data.response.status);
        }
    // }
  }
  async function showPosition(position: any) {
    let pass_data: any = {
      post: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        accuracy: position.coords.accuracy,
      },
    };
    if (position.coords.altitude) {
      pass_data.post.altitude = position.coords.altitude;
    }

    if (position.coords.altitude_accuracy) {
      pass_data.post.altitude_accuracy = position.coords.altitude_accuracy;
    }

    if (position.coords.heading) {
      pass_data.post.heading = position.coords.heading;
    }

    let data: any = await api("/company/update_current_location", pass_data);
  }
  async function init() {
    let enableLoader = false;
    let data: any = await api("/init");
    if (data.status_code == 200) {

      // window.fcWidget?.setExternalId(data.response.user_details.id);
      // window.fcWidget?.user.setFirstName(data.response.user_details.name);
      if (data.response?.tutorials) {
        SetTutorials(data.response?.tutorials);
      }

      //here current location
      if (data.response.has_account == true) {
        idle();
        // if (
        //   data.response.hasOwnProperty("application") &&
        //   data.response.application.platform === "web"
        // ) {
        //   getLocation();
        // }

        

        setNewApplicationStauts(data?.response?.new_application_status);
        if (data?.response?.new_application_status == 3) { 
          setPreviousRequest(data?.response?.previous_request);
          if (window.location.origin + "/signup/request" != window.location.href) {
            window.location.href = "/signup/request";
          }
        } else if (data?.response?.companies && data?.response?.companies.length == 0) {
          if (window.location.origin + "/signup/success" != window.location.href && window.location.origin + "/signup/success/applied" != window.location.href) {
            window.location.href = "/signup/success";
          }
        }
      } else {
        if (window.location.origin + "/signup" != window.location.href) {
          window.location.href = "/signup";
        }
      }
      if (data.response.hasOwnProperty("application")) {
        SetApplication({platform: data.response.application.platform});
        if (
          data.response.application.platform === "ios" ||
          data.response.application.platform === "android"
        ) {
          let mobileObj: any = {
            function: "dashboard",
            app_type: "react_web",
          };
          if (data.response.application.hasOwnProperty("token")) {
            mobileObj.userid = data.response.user_details.id;
            mobileObj.username = data.response.user_details.username;
            mobileObj.token = data.response.application.token;
          }
          if (data.response.application.hasOwnProperty("notification_token")) {
            mobileObj.notification_token =
              data.response.application.notification_token;
          } else {
            mobileObj.notification_token = null;
          }
          mobileApp(mobileObj);
          if (
            data.response?.currently_working == true &&
            data.response.settings.background_location_allowed
          ) {
            console.log('App startdeliverypartnerbglocation');
            
            setBgLocationService(true);
            mobileObj = {
              function: "startdeliverypartnerbglocation",
              app_type: "react_web",
            };
            mobileApp(mobileObj);
            enableLoader = true;
          }
          // const batteryopt = {
          // function: "disablebatteryoptimization",
          // app_type: "react_web",
          // };
          // mobileApp(batteryopt);
        }
      }
      if (data.response.hasOwnProperty("settings")) {
        SetSettings(data.response.settings);
      }
      if (data.response.hasOwnProperty("user_details")) {
        SetUserDetails(data.response.user_details);
      }
      if (data.response.hasOwnProperty("image_base_url")) {
        setImageBaseUrl(data.response.image_base_url);
      }
      if (data?.response?.companies && data?.response?.companies.length > 0) {
        let checkedCompany: any;
        data.response?.companies.map((item: any) => {
          if (item?.checked) {
            checkedCompany = item.brand_id;
          }
        });
        if (checkedCompany) {
          if (window.location.origin + "/" == window.location.href) {
            window.location.href = "/company/" + checkedCompany;
          }
        }
      }

      if(data.response.application.platform === "android"){
        newOrderTimer = setInterval(() => {
          getNewOrder();
        }, 10000);
        getNewOrder();
      }
    }
    setLoading(enableLoader);
  }

  async function idle() {
    let data: any = await api("/idle");
    if (data.status_code == 200) {

    }
  }

  return (
    <>
      <DataProvider
        settings={settings}
        user_details={user_details}
        application={application}
        image_base_url={imageBaseUrl}
        previous_request={previous_request}
        new_application_status={new_application_status}
        tutorials={tutorials}
        bg_location_service={bg_location_service}
        bg_location_access={bg_location_access}
      >
        {/* <Loader promiseTracker={usePromiseTracker} /> */}
        <BrowserRouter>
          <Routes>
            <Route path="signup" element={<Signup />} />
            <Route path="signup/request" element={<ActivationForm />} />
            <Route path="signup/success/:alert?" element={<SignupSuccess />} />

            <Route path="/" element={<HomeLayout />}>
              <Route path="profile" element={<Profile />} />

              <Route path="notification" element={<Notification />} />
              <Route index element={<Home />} />

              <Route path="order/:id" element={<Order />} />
              <Route
                path="order_details/:id/:alert?"
                element={<OrderDetails have_footer={false} />}
              />
              {/* <Route path="order_map" element={<OrderMap />} /> */}

              <Route path="companies" element={<Companies />} />
              <Route path="company/:id" element={<Company />} />
              <Route
                path="orders/active/:id"
                element={<Orders type="active" />}
              />
              <Route
                path="orders/history/:id"
                element={<Orders type="history" />}
              />
              <Route
                path="orders/cancelled/:id"
                element={<Orders type="cancelled" />}
              />
              <Route
                path="orders/expired/:id"
                element={<Orders type="expired" />}
              />
                <Route
                path="settlement"
                element={<Settlement />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </DataProvider>
    </>
  );
}

function RouteAdapter({ children }: any) {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
}

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     backgroundColor: '#fff',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
// });
